import axios from 'axios';
import {
    CHANGE_FIRST_LOGIN,
    CHANGE_RECOVERY_PASSWORD,
    RESET_PASSWORD_STATUS,
    RESPONSE_ERROR,
    SET_CURRENT_USER,
    VERIFY_FIRST_LOGIN,
    VERIFY_RECOVERY,
} from './types';
import { API_HOST } from '../../config';
import InitAction from '../action-creators/init';
import history from '../history';
// import { loadOpenReplayScript } from '../../utils/helpers';
import { showSearchPanel } from './gridActions';
import {ClientJS} from "clientjs";
const clientJS = new ClientJS();
//import { store } from 'redux/store';

const JSON_CONFIG = { headers: { 'Content-Type': 'application/json;charset=UTF-8' } };

export function responseError(status) {
    return {
        type: RESPONSE_ERROR,
        status,
    };
}

export function responseErrorExpiration(status) {
    return {
        type: 'RESPONSE_ERROR_EXPIRATION',
        status,
    };
}

export function setCurrentUser(user) {
    return {
        type: SET_CURRENT_USER,
        payload: user,
    };
}

export function setLastVisitedPath(path) {
    return {
        type: 'SET_LAST_VISITED_PATH',
        payload: path,
    };
}

export function verifyFirstLogin(res) {
    return {
        type: VERIFY_FIRST_LOGIN,
        res,
    };
}

export function changeFirstLogin(res) {
    return {
        type: CHANGE_FIRST_LOGIN,
        res,
    };
}

// TODO: @Deprecated
export function resetPassword(res) {
    return {
        type: RESET_PASSWORD_STATUS,
        res,
    };
}

export function verifyRecovery(res) {
    return {
        type: VERIFY_RECOVERY,
        res,
    };
}

export function changeRecoveryPassword(res) {
    return {
        type: CHANGE_RECOVERY_PASSWORD,
        res,
    };
}

export function changeExpirationPassword(res) {
    return {
        type: 'CHANGE_EXPIRATION_PASSWORD',
        res,
    };
}

export function logout() {
    return (dispatch, getState) => {
        return axios
            .post(
                API_HOST + '/logout',
                {},
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    withCredentials: true, // important for dev mode via proxy
                },
            )
            .then(() => {
                dispatch({ type: 'LOGOUT_SUCCESS' });

                if (getState().router.location) {
                    const { pathname } = getState().router.location;
                    const { code } = getState().app.auth.user;
                    const sidebar = getState().app.menu.sidebarCollapsed;

                    if (typeof code !== 'undefined') {
                        dispatch({ type: 'RESET_APP' });
                        //dispatch(setLastVisitedPath({ [ code ]: pathname }));

                        if (!sidebar) {
                            dispatch({ type: 'TOGGLE_SIDEBAR' });
                        }
                    }
                }
                dispatch(setCurrentUser({}));
                history.replace('/login');
            });
    };
}

export function loginNew(data) {
    // console.log('loginNew', data);
    return (dispatch) => {
        let link = API_HOST + '/login?t=' + (+new Date());
        link += data[ 'authType' ] ? '&authType=' + data[ 'authType' ] : '';
        link += data[ 'accessCode' ] ? '&accessCode=' + data[ 'accessCode' ] : '';
        link += data[ 'authenticatorCode' ] ? '&mfa=' + data[ 'authenticatorCode' ] : '';
        link += data[ 'reCaptchaValue' ] ? '&recaptcha=' + data[ 'reCaptchaValue' ] : '';
        link += data[ 'isTrustedDevice' ] ? '&isTrustedDevice=' + data[ 'isTrustedDevice' ] : '';
        link += data[ 'visitorId' ] ? '&visitorId=' + data[ 'visitorId' ] : '';
        const rawUserData = data.username + ':' + data.password;
        const authorization = 'Basic ' + Buffer.from(rawUserData, 'utf8').toString('base64');

        return axios
            .post(link, data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    Authorization: authorization,
                },
                withCredentials: process.env.NODE_ENV !== 'production',
            })
            .then((res) => {
                if (res.data) {
                    dispatch({ type: 'LOGIN_SUCCESS' });
                    dispatch(showSearchPanel());
                    dispatch(setCurrentUser(res.data));
                    dispatch(InitAction(res.data));
                    sessionStorage.clear()
                }
                if (res.status === 204) {
                    const errorMessage = 'This user is not existing in the system';
                    dispatch(responseError(errorMessage));
                    return {
                        statusCode: res.status,
                        error: errorMessage
                    }
                }
            })
            .catch((error) => {
                console.error('****CATCH****', error);
                if (error.response) {
                    if (error.response.status && error.response.status === 461) {
                        // history.push('/login/expiration-password');
                    } else {
                        dispatch(responseError(error.response.status));
                    }
                } else if (error.request) {
                    console.error(error.request);
                } else {
                    console.error('Error', error.message);
                    dispatch(responseError(error.message));
                }
                return {
                    statusCode: error.response.status,
                    error: error.message
                }
            });
    };
}

// Reviewed section ...
const authEndpoint = (path) => {
    return `${API_HOST}${path}`;
}

export function mfaOptionsForLogin(data) {
    return () => {
        const clientJS = new ClientJS();
        const visitorId = clientJS.getFingerprint();

        return axios.create({
            withCredentials: true, // Include credentials
        }).post(authEndpoint(`/mfa/auth-options-for-login`), data, JSON_CONFIG)
            .then((res) => {
                // console.log('mfaOptionsForLogin', res.data);
                /*if (res.data?.trackEnabled) {
                  loadOpenReplayScript(data.email);
                }*/
                return res.data;
            })
            .catch(function (error) {
                console.error('mfaOptionsForLogin', error);
                return {
                    error: error.message
                }
            });
    };
}


export function mfaOptionsForRecovery(data) {
    return () => {
        return axios
            .post(authEndpoint('/mfa/auth-options-for-recovery'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('mfaOptionsForRecovery', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error('mfaOptionsForRecovery', error);
                return {
                    error: error.message
                }
            });
    };
}
export function mfaOptionsForFirstLogin(data) {
    return () => {
        return axios
            .post(authEndpoint('/mfa/auth-options-for-first-login'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('mfaOptionsForRecovery', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error('mfaOptionsForRecovery', error);
                return {
                    error: error.message
                }
            });
    };
}

export function mfaAccessCodeForLogin(data) {
    return () => {
        return axios.post(authEndpoint('/mfa/access-code-for-login'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('mfaAccessCodeForLogin', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error(error);
                return {
                    error: error.message
                }
            });
    };
}

export function mfaAccessCodeForRecovery(data) {
    return () => {
        return axios
            .post(authEndpoint('/mfa/access-code-for-recovery'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('mfaAccessCodeForRecovery', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error(error);
                return {
                    error: error.message
                }
            });
    };
}

export function mfaAccessCodeForFirstLogin(data) {
    return () => {
        return axios
            .post(authEndpoint('/mfa/access-code-for-first-login'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('mfaAccessCodeForFirstLogin', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error(error);
                return {
                    error: error.message
                }
            });
    };
}

export function mfaResetForgottenPassword(data) {
    // console.log('resetForgottenPassword', data);
    return () => {
        return axios
            .post(authEndpoint('/mfa/password-reset'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('resetForgottenPasswordResult', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error('resetForgottenPasswordError', error);
                return {
                    error: error.message
                }
            });
    };
}

export function mfaPasswordRecovery(data) {
    // console.log('passwordRecovery', data);
    return () => {
        return axios
            .post(authEndpoint('/mfa/password-recovery'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('passwordRecoveryResult', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error('passwordRecoveryError', error);
                return {
                    error: error
                }
            });
    };
}

export function mfaPasswordChange(data) {
    // console.log('mfaPasswordChange', data);
    return () => {
        return axios
            .post(authEndpoint('/mfa/password-change'), data, JSON_CONFIG)
            .then((res) => {
                // console.log('mfaPasswordChangeResult', res.data);
                return res.data;
            })
            .catch(function (error) {
                console.error('mfaPasswordChangeError', error);
                return {
                    error: error
                }
            });
    };
}

export function mfaPasswordChangeAtFirstLogin(data) {
    // console.log('mfaPasswordChangeAtFirstLogin', data);
    return () => {
        return axios
            .post(authEndpoint('/mfa/password-change-first-login'), data, JSON_CONFIG)
            .then((res) => {
                return res;
            })
            .catch(function (error) {
                console.error('mfaPasswordChangeAtFirstLoginError', error);
                return {
                    error: error
                }
            });
    };
}
