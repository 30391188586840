import React, { useCallback, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { loginNew, mfaAccessCodeForLogin, mfaOptionsForLogin } from '../../../redux/actions/authActions';
import useInterval from './useInterval';
import { getAllCaseTypes } from '../../../redux/actions/cases/caseTypesActions';
import { getAllTypes } from '../../../redux/actions/reviewerTypesAction';
import { getAllUsersIfEmpty } from '../../../redux/action-creators/users';
import { getAll } from '../../../redux/actions/BasicEntityActions';
import { getLayoutItems, fetchTranslatesItems } from 'redux/action-creators/init';
import {
  AUTH_TYPE,
  Button,
  delayedApiCall,
  err,
  log,
  ReCaptcha,
  TabNav,
  TabNavItem,
  TWAuthLayout,
  TWInlineError,
  TWFormElementAccessCode,
  TWFormElementAuthenticatorCode,
  TWFormElementEmail,
  TWFormElementPassword,
  warn,
} from './utils';

import { ClientJS } from 'clientjs';

const FORM_STATE = {
  EDIT: 'EDIT',
  PASSWORD: 'PASSWORD',
  ACCESS_CODE: 'ACCESS_CODE',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export const Login2FAForm = () => {
  const dispatch = useDispatch();
  const emailRef = useRef();
  const passwordRef = useRef();
  const accessCodeRef = useRef();
  const authenticatorCodeRef = useRef();
  const recaptchaRef = useRef();
  const clientJS = new ClientJS();

  const [state, setStateOriginal] = useState({
    formState: FORM_STATE.EDIT,
    activeTab: null,
    isLoading: false,
    recaptchaLoaded: false,
    needRecaptcha: false,
    needMfa: false,
    canShowEmailTab: false,
    canShowAuthenticatorTab: false,
    canShowSmsTab: false,
    redirectPage: '',
    extEmailError: '',
    extPasswordError: '',
    extAccessCodeError: '',
    extAuthenticatorCodeError: '',
  });

  const { automaticLogoutPreviousPath, lastVisitedPath, currentUser } = useSelector((state) => {
    const { user } = state.app.auth;

    return {
      automaticLogoutPreviousPath: state.app.loginRedirect.redirectTo,
      currentUser: user,
      lastVisitedPath: state.app.auth.logoutPath[user ? user.code : null],
    };
  }, shallowEqual);

  const setState = useCallback(
    (newState) => {
      setStateOriginal({ ...state, ...newState });
    },
    [setStateOriginal, state],
  );

  // TODO
  const { code } = currentUser;
  const pathToRedirectAfterLogin = useMemo(() => {
    if (code) {
      warn('pathWarn', code, lastVisitedPath, automaticLogoutPreviousPath);
      return !lastVisitedPath || lastVisitedPath === '/login' ? automaticLogoutPreviousPath : lastVisitedPath;
    }
    return '/';
  }, [automaticLogoutPreviousPath, code, lastVisitedPath]);

  const reload = () => {
    localStorage.setItem('shouldRefresh', 'false');
    caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  })
  window.location.reload();

  }
 
  const ctaMfaOptions = () => {
    const email = emailRef.current;
    if (!email || !email.isValid()) {
      return;
    }
    const visitorId = clientJS.getFingerprint();
    const currentEmail = email.getValue();
    const dto = {
      email: currentEmail,
      visitorId: visitorId
    };
    log('ctaMfaOptions', dto);
    setState({ isLoading: true });
    delayedApiCall(() => {
      dispatch(mfaOptionsForLogin(dto)).then(
        (result) => {
          // log('mfaOptionsForLoginResult', result);
          if (!result) return;
          if (result.error) {
            err('ctaMfaOptions-ERROR', result.error);
            setState({ isLoading: false, extEmailError: 'Backend is unavailable at the moment. Please try again later.' });
          } else if (result.authOptions) {
            log('ctaMfaOptions - OK', result);
            const visitorId = clientJS.getFingerprint();

            const canShowEmailTab = result.authOptions.includes(AUTH_TYPE.EMAIL);
            const canShowAuthenticatorTab = result.authOptions.includes(AUTH_TYPE.AUTHENTICATOR);
            const canShowSmsTab = result.authOptions.includes(AUTH_TYPE.SMS);
            const activeTab = canShowEmailTab ? 'mfa-email' : canShowAuthenticatorTab ? 'mfa-authenticator' : canShowSmsTab ? 'mfa-sms' : null;
            log('activeTab', activeTab, canShowEmailTab, canShowAuthenticatorTab, canShowSmsTab);
            const trustedDeviceCookie = result.trustedDevice;
            const isNeedMfa = trustedDeviceCookie ? false : result.authOptions.length > 0;

            setState({
              formState: FORM_STATE.PASSWORD,
              isLoading: false,
              needRecaptcha: result.needRecaptcha,
              needMfa: isNeedMfa,
              activeTab: activeTab,
              extEmailError: '',
              extPasswordError: '',
              extAccessCodeError: '',
              extAuthenticatorCodeError: '',
              canShowEmailTab: canShowEmailTab,
              canShowAuthenticatorTab: canShowAuthenticatorTab,
              canShowSmsTab: canShowSmsTab,
              visitorId: visitorId,
            });
          } else {
            setState({ isLoading: false, extEmailError: 'Something went wrong.' });
          }
        },
        (e) => {
          setState({ isLoading: false, errors: { email: 'Unknown error.' } });
          err('2FA-ERROR', e);
        },
      );
    });
  };

  const authTypeFromActiveTab = () => {
    switch (state.activeTab) {
      case 'mfa-email':
        return AUTH_TYPE.EMAIL;
      case 'mfa-sms':
        return AUTH_TYPE.SMS;
      case 'mfa-authenticator':
        return AUTH_TYPE.AUTHENTICATOR;
      default:
        return null;
    }
  };

  const generateDtoForRequestCodeVia = () => {
    const email = emailRef.current;
    const password = passwordRef.current;
    const result = { email: email.getValue(), password: password.getValue(), authType: authTypeFromActiveTab() };
    if (!email || !email.isValid() || !password || !password.isValid()) {
      warn('ctaMfaRequestCodeVia - MISSING PARAMS', result);
      return null;
    }
    return result;
  };

  const [accessCodeCounter, setAccessCodeCounter] = useState(0);
  useInterval(() => {
    if (state.formState === FORM_STATE.ACCESS_CODE && accessCodeCounter === 0) {
      warn('Access code has expired');
      setState({ formState: FORM_STATE.EDIT });
      return;
    }
    if (state.formState !== FORM_STATE.ACCESS_CODE || accessCodeCounter <= 0) {
      return;
    }
    // log('accessCodeCounter', accessCodeCounter);
    setAccessCodeCounter((currentCount) => currentCount - 1);
  }, 1000);

  const ctaMfaRequestCodeVia = () => {
    const dto = generateDtoForRequestCodeVia();
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('ctaMfaRequestCodeVia', dto);
      dispatch(mfaAccessCodeForLogin(dto)).then(
        (result) => {
          if (result) {
            // Prevents to trigger the FORM_STATE.EDIT when changing of the formState.
            setAccessCodeCounter(result.expireInSeconds);
            // setAccessCodeCounter(10);
            setState({
              formState: FORM_STATE.ACCESS_CODE,
              isLoading: false,
              extEmailError: '',
              extPasswordError: '',
              extAccessCodeError: '',
              extAuthenticatorCodeError: '',
            });
          } else {
            setState({ isLoading: false, extPasswordError: 'Access Code Error' });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const generateDtoForLogin = (captchaValue) => {
    const email = emailRef.current;
    const password = passwordRef.current;
    const accessCode = accessCodeRef.current;
    const authenticatorCode = authenticatorCodeRef.current;
    const authType = authTypeFromActiveTab();
    const visitorId = clientJS.getFingerprint();
    const result = {
      username: email.getValue(),
      password: password.getValue(),
      authType: authType,
      reCaptchaValue: captchaValue,
      isTrustedDevice: accessCode?.isTrustedDevice() || authenticatorCode?.isTrustedDevice(),
      visitorId: visitorId,
    };
    const isValidEmail = email && email.isValid();
    const isValidPassword = password && password.isValid();
    if (needMfa) {
      if (authType === AUTH_TYPE.AUTHENTICATOR) {
        const isValidAuthenticatorCode = authenticatorCode && authenticatorCode.isValid();
        if (!isValidEmail || !isValidPassword || !isValidAuthenticatorCode) {
          warn('generateDtoForLogin (AuthenticatorCode) - MISSING PARAM', result);
          return null;
        }
        result['authenticatorCode'] = authenticatorCode.getValue();
      } else if (authType === AUTH_TYPE.EMAIL || authType === AUTH_TYPE.SMS) {
        const isValidAccessCode = accessCode && accessCode.isValid();
        if (!isValidEmail || !isValidPassword || !isValidAccessCode) {
          warn('generateDtoForLogin (AccessCode) - MISSING PARAM', result);
          return null;
        }
        result['accessCode'] = accessCode.getValue();
      }
    } else {
      if (!isValidEmail || !isValidPassword) {
        warn('generateDtoForLogin - MISSING PARAM', result);
        return null;
      }
    }
    return result;
  };

  const ctaLogin = (captchaValue) => {
    log('ctaLogin', captchaValue);
    const dto = generateDtoForLogin(captchaValue);
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      dispatch(loginNew(dto)).then(
        (result) => {
          log('loginNewResult', result);
          if (result && result.error) {
            let errorMessage;
            if (result.statusCode === 204) {
              errorMessage = 'This user is not existing in the system';
            } else if (result.statusCode === 461) {
              errorMessage = 'Password expired.';
              setState({ formState: FORM_STATE.ERROR, isLoading: false, redirectPage: '/login/password-expiration' });
            } else if (result.statusCode === 453) {
              errorMessage = 'Number of attempts has expired. Please contact the support.';
            } else if (result.statusCode === 458) {
              errorMessage = 'Invalid cookie. Please contact the support.';
            } else {
              errorMessage = needMfa ? 'Invalid or expired password or access code.' : 'Invalid or expired username or password.';
            }
            err('errorMessage', errorMessage);
            if (needMfa) {
              setState({ isLoading: false, extAccessCodeError: errorMessage });
            } else {
              setState({ isLoading: false, extPasswordError: errorMessage });
            }
          } else if (result) {
            setState({ formState: FORM_STATE.ACCESS_CODE, isLoading: false });
          } else {
            initializeDataFetching();
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false });
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  /**
   * Required backend api calls at login. We store their results in local storage, in redux states.
   */
  function initializeDataFetching() {
    dispatch(getAllCaseTypes());
    dispatch(getAllTypes());
    dispatch(getAll('gender'));
    dispatch(getAll('body_part'));
    dispatch(getAllUsersIfEmpty());
    dispatch(getLayoutItems());
    dispatch(fetchTranslatesItems());
  }


  const ctaLoginCaptcha = () => {
    log('ctaLoginCaptcha', state, needRecaptcha, recaptchaRef);
    if (needRecaptcha && recaptchaRef) {
      const captcha = recaptchaRef.current;
      if (!generateDtoForLogin()) {
        warn('Invalid form before captcha');
        return;
      }
      if (!captcha || !captcha.isReady()) {
        warn('Captcha is not ready');
        return;
      }
      captcha.callback((captchaValue) => {
        ctaLogin(captchaValue);
      });
    } else {
      ctaLogin();
    }
  };

  const ctaLoginAuthenticator = (captchaValue) => {
    log('ctaLoginAuthenticator', state);
    const dto = generateDtoForLogin(captchaValue);
    if (!dto) {
      return;
    }
    setState({ isLoading: true });
    delayedApiCall(() => {
      log('loginAuthenticator', dto);
      dispatch(loginNew(dto)).then(
        (result) => {
          log('login', result);
          if (result && result.error) {
            let errorMessage;
            if (result.statusCode === 204) {
              errorMessage = 'This user is not existing in the system';
            } else if(result.statusCode === 461) {
              errorMessage = 'Password expired.';
              setState({ formState: FORM_STATE.ERROR, isLoading: false, redirectPage: '/login/password-expiration' });
            } else {
              errorMessage = 'Invalid or expired password or authenticator code.';
            }
            setState({ isLoading: false, extAuthenticatorCodeError: errorMessage });
          } else if (result) {
            setState({ formState: FORM_STATE.ACCESS_CODE, isLoading: false });
          } else {
            setState({ formState: FORM_STATE.SUCCESS, isLoading: false });
            initializeDataFetching();
          }
        },
        (e) => {
          setState({ isLoading: false });
          err('error', e);
        },
      );
    });
  };

  const ctaLoginCaptchaAuthenticator = () => {
    log('ctaLoginCaptchaAuthenticator', state, needRecaptcha, recaptchaRef);
    if (needRecaptcha && recaptchaRef) {
      const captcha = recaptchaRef.current;
      if (!generateDtoForLogin()) {
        warn('Invalid form before captcha');
        return;
      }
      if (!captcha || !captcha.isReady()) {
        warn('Captcha is not ready');
        return;
      }
      captcha.callback((captchaValue) => {
        ctaLoginAuthenticator(captchaValue);
      });
    } else {
      ctaLoginAuthenticator();
    }
  };

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const onTabSelection = (e) => {
    // log('onTabSelection', e);
    setState({ activeTab: e });
  };

  const {
    formState,
    activeTab,
    isLoading,
    recaptchaLoaded,
    needRecaptcha,
    needMfa,
    redirectPage,
    canShowEmailTab,
    canShowAuthenticatorTab,
    canShowSmsTab,
    extEmailError,
    extPasswordError,
    extAuthenticatorCodeError,
    extAccessCodeError,
  } = state;

  if (formState === FORM_STATE.ERROR && redirectPage) {
    // Redirect at Password Expiration
    log('redirectPage', redirectPage);
    return <Redirect to={redirectPage + ''} />;
  } else if (formState === FORM_STATE.SUCCESS && currentUser && currentUser.code) {
    // Redirect at Successful Login
    return <Redirect to={pathToRedirectAfterLogin + ''} />;
  }

  const notInEditState = formState !== FORM_STATE.EDIT;
  const shouldRefresh = localStorage.getItem('shouldRefresh') === 'true';
  const isNetworkErr = sessionStorage.getItem('isNetworkError') === 'true';

  const title = localStorage.getItem('shouldRefresh') === 'true' ? 'New version is available please refresh your browser.' : 'Sign in to start your session';

  return (
    <TWAuthLayout title={title}>
      {isNetworkErr &&
        <TWInlineError errorMessage = 'A network error occurred during our last save attempt. Please, log in again and check the contents of the last field you edited.'></TWInlineError>
      }
      <form className="space-y-2" action="#" method="POST" noValidate={true} onSubmit={onSubmit}>
        {/*email*/}
        <TWFormElementEmail
          id={'2fa-email'}
          ref={emailRef}
          show={formState !== FORM_STATE.SUCCESS && !shouldRefresh}
          disabled={isLoading || notInEditState}
          showEditButton={notInEditState}
          onEditClick={() => {
            setState({ formState: FORM_STATE.EDIT, extEmailError: '', extPasswordError: '' });
          }}
          extError={extEmailError}
        />
        {/*continue button*/}
        <Button show={formState === FORM_STATE.EDIT} isLoading={isLoading} cta={shouldRefresh ? reload : ctaMfaOptions}>
          {shouldRefresh ? 'Refresh' : 'Continue'}
        </Button>
        {/*password*/}
        <TWFormElementPassword
          show={formState === FORM_STATE.PASSWORD || formState === FORM_STATE.ACCESS_CODE}
          id={'2fa-password'}
          ref={passwordRef}
          autoFocus={true}
          showForgotPassword={true}
          disabled={isLoading || formState !== FORM_STATE.PASSWORD}
          extError={extPasswordError}
        />

        {/*tab navigation*/}
        {needMfa && formState === FORM_STATE.PASSWORD && (
          <>
            <p className={'font-medium'}>Please select a multi-factor authentication (MFA)</p>
            <TabNav defaultActiveTab={activeTab ? activeTab : 'mfa-email'} onTabSelection={onTabSelection}>
              {/*Email*/}
              <TabNavItem show={canShowEmailTab} title={'Email'} tabKey={'mfa-email'}>
                {formState === FORM_STATE.PASSWORD && (
                  <>
                    <p className={'mb-4 text-sm'}>You will receive an access code via E-mail, which you can enter to log into the system.</p>
                    <Button isLoading={isLoading} cta={ctaMfaRequestCodeVia} show={!activeTab || activeTab === 'mfa-email'}>
                      Request code via Email
                    </Button>
                  </>
                )}
              </TabNavItem>
              {/*Google Authenticator*/}
              <TabNavItem show={canShowAuthenticatorTab} title={'Authenticator'} tabKey={'mfa-authenticator'}>
                <>
                  <TWFormElementAuthenticatorCode
                    show={true}
                    id={'2fa-authenticator-code'}
                    ref={authenticatorCodeRef}
                    disabled={isLoading}
                    extError={extAuthenticatorCodeError}
                  />
                  <Button isLoading={isLoading && !recaptchaLoaded} cta={ctaLoginCaptchaAuthenticator} show={activeTab === 'mfa-authenticator'}>
                    Sign in
                  </Button>
                </>
              </TabNavItem>
              {/*SMS*/}
              <TabNavItem show={canShowSmsTab} title={'SMS'} tabKey={'mfa-sms'}>
                <>
                  <p className={'mb-4 text-sm'}>You will receive an access code via SMS, which you can enter to log into the system.</p>
                  <Button isLoading={isLoading} cta={ctaMfaRequestCodeVia} show={activeTab === 'mfa-sms'}>
                    Request code via SMS
                  </Button>
                </>
              </TabNavItem>
            </TabNav>
          </>
        )}
        {/*access code*/}
        {needMfa && formState === FORM_STATE.ACCESS_CODE && (
          <>
            <TWFormElementAccessCode
              show={true}
              id={'2fa-access-code'}
              ref={accessCodeRef}
              disabled={isLoading || formState !== FORM_STATE.ACCESS_CODE}
              accessCodeExpirationCounter={accessCodeCounter}
              extError={extAccessCodeError}
            />
            <p className={'mb-4 text-sm'}>Please, enter the access code you received via {activeTab === 'mfa-email' ? 'E-mail' : 'SMS'}.</p>
            <Button isLoading={isLoading && !recaptchaLoaded} cta={ctaLoginCaptcha} show={true}>
              Sign in
            </Button>
          </>
        )}
        {/*sign in*/}
        <Button show={!needMfa && formState === FORM_STATE.PASSWORD} isLoading={isLoading && !recaptchaLoaded} cta={ctaLoginCaptcha}>
          Sign in
        </Button>
        {/*recaptcha*/}
        <ReCaptcha enabled={needRecaptcha} ref={recaptchaRef} />
      </form>
    </TWAuthLayout>
  );
};
